import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import 'normalize.css'
import './style/index.scss'

import Vant from 'vant'
import 'vant/lib/index.css'

import FastClick from 'fastclick'
import '@/bridge/bridge-h5.js'

import VConsole from 'vconsole'
// eslint-disable-next-line no-new
new VConsole()

FastClick.attach(document.body)

Vue.use(Vant)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
