!(function (windowz, n) {
  n(windowz, !0)
}(window, function (windowz, open) {
  /**
   * 调用JSBridge执行api
   * @param {String} apiName
   * @param {Any} params
   * @param {
   *    success:((res:String)->Unit)?,
   *    fail:((res:String)->Unit)?)?
   *    cancel:((res:String)->Unit)?)?
   *    complete:((res:String)->Unit)?)? 操作完成后一定会调用
   * } callBack res对象包括 errorCode errorMsg
   */
  function call (apiName, params, callBack) {
    if (!windowz.jsBridge) { // 没有jsBridge
      defCall(apiName, callBack)
    } else {
      windowz.jsBridge.call(apiName, checkParams(params), function (ret) {
        // 解决中文乱码
        ret = JSON.parse(decodeStr(JSON.stringify(ret)))
        console.log(`jsBridge ${JSON.stringify(ret)}`)
        callBack._complete && callBack._complete(ret) || delete callBack._complete

        const errorCode = ret && ret.errorCode
        switch (errorCode) {
          case 0:
            callBack.success && callBack.success(ret)
            break
          case 1:
            callBack.cancel && callBack.cancel(ret)
            break
          default:
            callBack.fail && callBack.fail(ret)
            break
        }
        callBack.complete && callBack.complete(ret)
      })
    }
  }

  function checkParams (params) {
    return params || {}
  }

  function defCall (apiName, callBack) {
    callBack.complete && callBack.complete()
    console.log('jsBridge not found ,' + apiName)
  }

  function on (eventName, callBack) {
    windowz.jsBridge ? windowz.jsBridge.on(eventName, function (ret) {
      callBack(ret)
    }) : defOn(eventName, callBack)
  }

  function defOn (eventName, callBack) {
    callBack.complete && callBack.complete()
    console.log('jsBridge not found ,' + eventName)
  }

  const api = {
    // base
    config: function (e) {
      call('config', {}, (e._complete = function () {
        console.log('_complete config')
      }, e))
    },
    getAppData: function (e) {
      call('getAppData', {}, e)
    },
    // utils
    goBack: function (e) {
      call('goBack', {}, e)
    },
    closeWindow: function (e) {
      call('closeWindow', {}, e)
    },
    getStatusBarHeight: function (e) {
      call('getStatusBarHeight', {}, e)
    },
    setStatusBar: function (e) {
      call('setStatusBar', {
        statusBarColor: e.statusBarColor || 0xffffff,
        alpha: e.alpha || 0,
        isDark: e.isDark || false,
        isTransparent: e.isTransparent || true
      }, e)
    },
    setStorage: function (e) {
      call('setStorage', {
        key: e.key || '',
        value: e.value || ''
      }, e)
    },
    getStorage: function (e) {
      call('getStorage', {
        key: e.key || ''
      }, e)
    },
    actionWithUrl: function (e) {
      call('actionWithUrl', {
        actionUrl: e.actionUrl || ''
      }, e)
    },
    // topBar
    setTitle: function (e) {
      call('setTitle', {
        title: e.title || ''
      }, e)
    },
    setActionButton: function (e) {
      const params = {
        actions: e.actions
      }
      call('setActionButton', params, e)
    },
    // image
    takePhoto: function (e) {
      call('takePhoto', {}, e)
    },
    chooseImage: function (e) {
      call('chooseImage', {
        count: e.count || (e.count == undefined ? 0 : e.count)
      }, e)
    },
    previewImage: function (e) {
      call('previewImage', {
        urls: e.urls || [],
        index: e.index || (e.index == undefined ? 0 : e.index)
      }, e)
    },

    chooseVideo: function (e) {
      call('chooseVideo', {
        count: e.count || (e.count == undefined ? 0 : e.count)
      }, e)
    },
    callAndroid: function (e) {
      call("callAndroid", e, e)
    },
    // uploadVideos: function (e) {
    //     call("uploadVideos", {
    //         nativeResourceUrls: e.nativeResourceUrls || []
    //     }, e)
    // },
    // previewVideo: function (e) {
    //     call("previewVideo", {
    //         url: e.url || ""
    //     }, e)
    // },
    //
    // chooseFile: function (e) {
    //     call("chooseFile", {
    //         enableCount: e.enableCount || (e.enableCount == undefined ? 0 : e.enableCount),
    //         maxSize: e.maxSize || (e.maxSize == undefined ? 0 : e.maxSize),
    //         isMultiSelect: e.isMultiSelect || (e.isMultiSelect == undefined ? true : e.isMultiSelect)
    //     }, e)
    // },
    // uploadFile: function (e) {
    //     call("uploadFile", {
    //         nativeResourceUrls: e.nativeResourceUrls || []
    //     }, e)
    // },
    // previewFile: function (e) {
    //     call("previewFile", {
    //         url: e.url || ""
    //     }, e)
    // },

    chooseLocation: function (e) {
      call('chooseLocation', {}, e)
    },
    getLocation: function (e) {
      call('getLocation', {}, e)
    },
    previewLocation: function (e) {
      call('previewLocation', {}, e)
    },
    onContainerResume: function (e) {
      on('onContainerResume', e)
    },
    onContainerPause: function (e) {
      on('onContainerPause', e)
    }
  }
  return open && (windowz.jsBidgeApi = api), api
}))

/**
 * 解决乱码
 * @param str
 * @returns {string}
 *
 * https://segmentfault.com/a/1190000021740379
 */
function decodeStr (str) {
  var out, i, len, c
  var char2, char3
  out = ''
  len = str.length
  i = 0
  while (i < len) {
    c = str.charCodeAt(i++)
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += str.charAt(i - 1)
        break
      case 12:
      case 13:
        // 110x xxxx 10xx xxxx
        char2 = str.charCodeAt(i++)
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F))
        break
      case 14:
        // 1110 xxxx 10xx xxxx 10xx xxxx
        char2 = str.charCodeAt(i++)
        char3 = str.charCodeAt(i++)
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0))
        break
    }
  }
  return out
}
