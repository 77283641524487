import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/tdjj'
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home/index.vue')
  },
  {
    path: '/tdjj',
    name: 'Tdjj',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Tdjj/index.vue')
  },
  {
    path: '/axfile',
    name: 'Axfile',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Axfile/index.vue')
  },
  {
    path: '/test',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Test/index.vue')
  },
  {
    path: '/test1',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Test1/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
